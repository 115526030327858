import React from "react"
import { Link } from "gatsby"
import marilyn from "../images/marilyn.svg"

// import Layout from "../components/layout"
import SEO from "../components/Seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div
      style={{
        backgroundImage: "linear-gradient(135deg, #684ca0 35%, #1c4ca0 100%)",
      }}
      className="gradient text-white min-h-screen flex items-center"
    >
      <div className="container mx-auto p-4 flex flex-wrap items-center">
        <div className="w-full md:w-5/12 text-center p-4">
          <img src={marilyn} alt="Not Found" />
        </div>
        <div className="w-full md:w-7/12 text-center md:text-left p-4">
          <div className="text-6xl font-medium">404</div>
          <div className="text-xl md:text-3xl font-medium mb-4">
            Oops. This page has gone missing.
          </div>
          <div className="text-lg mb-8">
            You may have mistyped the address or the page may have moved.
          </div>
          <Link to="/">Go back to the homepage</Link>
        </div>
      </div>
    </div>
  </>
)

export default NotFoundPage
